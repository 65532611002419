// this file will not make it to browsers unless you include it in a build target - see assets.json. E.g.:
//
//      "main.js": {
//          "main": true,
//          "files": [
//              "scripts/example.js"
//          ]
//      }


(function($) {
    $('body').on('click', '[data-dropform-trigger]', function(e) {
jQuery('html,body').animate({scrollTop: 0},'fast');
    });

})(jQuery);